.terms h1{
font-size:250% !important;
}
.terms h2{
font-size:110% !important;
padding-top:20px !important;
}
.terms h3{
padding-top:20px !important;
}
#page-body a{
color:red;
display:inline-block;
}
.first-section-content-wrap{
    width: 100vw;
    display: flex;
    justify-content: end;
}
.second-section-content-wrap{
    width: 100vw;
    display: flex;
    justify-content: end;
}
.first-section-content, .second-section-content-wrap{
    background-color:white;
    padding: 20px;
    margin: 20px auto;
    border-radius: 20px;
    width: 80%;
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.068), 0 6px 20px 0 rgba(0, 0, 0, 0.048);
}
.first-section-content h2{
margin-bottom: 20px !important;
font-size: 4vh;
}
.second-section-content-wrap h3{
margin-bottom: 20px !important;
font-size: 4vh;

}