@import './main.scss';

// .campaign_text
// {
//     font-size: 3rem;
//     display: flex;
//     align-items: center;
//     justify-content: center;
    
// }

// .box
// {
//     background-image: linear-gradient(to top, $violetColor 0%, $ashcolor 100%);
//     height: 400px;
//     width: 70%;
//     border-radius: 8px;
//     position: relative;
//     margin-left: 5rem;
// }

// .cam_img
// {
//     position: absolute;
    // width: 150px;
    // height: 150px;
    // margin-top: -2rem;
    // left: 15%;
    // display: flex;
//     top: -2rem;
//     object-fit: contain;
//     align-self: center;
//     z-index: 1;
// }

// .vertical_text
// {
//     writing-mode: vertical-lr;
//     text-orientation: mixed;
//     transform: matrix(-1, 0, 0, -1, 0, 0);
//     position: absolute;
//     font-size: 28px;
//     font-weight: 500;
//     left: -1rem;
//     bottom: 1rem;
//     margin-left: -1rem;
// }



// .content
// {
//     position: absolute;
//     margin-top: 10rem;
//     margin-left: 3rem;
// }


// @media only screen and (min-width: 768px) {

//     .box{
//         margin-left: 4rem;
//     }

//     .padding-top-65
//     {
//         padding-top: 65rem;
//     }

    
// }



// @media only screen   
// and (min-device-width : 360px)   
// and (max-device-width : 640px)  
// {
//     .padding-top-17
//     {
//         padding-top: 17rem;
//     }

//     .campaign_text
//     {
//         padding-top: 10rem;
//     }

//     .margin-top-10
//     {
//         padding-top: 17rem;
//     }

//     .top-10{
//         padding-top: 30rem;
//     }

//     .m-top-10
//     {
//         padding-top: 0rem;
//     }

//     .padding-top-82
//     {
//         padding-top: 82rem;
//     }

//     .p-top-30
//     {
//         padding-top: 30rem;
//     }
// }




//css doone by ashique

.compain-main-div{
    padding:0 5vw;
    margin-top:100px;
}

.main-div{
    background-color: #000914;
    justify-content: center;
    display: flex;
}

@media (max-width:700px){
    .main-div{
        flex-direction: column;
    }
}

/* =============================common rottate card cub============================================= */

#campain-heading-tag{
    color: white;
    font-size: 5vw;
    font-weight: 100;
}

@media (max-width:700px){
    #campain-heading-tag{
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }
}

.cube{
    position: relative;
    margin: 3vw;
    width: 100%;
    height: 800px;
    transform-style: preserve-3d;
    transform: rotateX(-30deg);
    animation: cube_animate 10s linear infinite;
    text-align: center;
}

@media (max-width:700px){
    .cube{
        width: 100%;
        margin-left:0;
        height:550px;
    }
}

.cube:hover{
    animation: none;
    cursor: pointer;
}


.cube div{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: unset;
    background: url('../../src/assets/images/ashi.jpg');
    /* background-position: left 15vw; */
    background-size: cover;
    background-repeat: no-repeat;
}

.top::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transform: translateZ(-400px);
    box-shadow: 0 0 120px rgba(255, 255, 255, 0.068),
    0 0 15px rgba(0, 38, 255, 0.7),
    0 0 30px rgba(6, 176, 255, 0.6);
}


@keyframes cube_animate{
    0%{
        transform: rotateX(-30deg) rotateY(0deg) ;
    }
    25%{
        transform: rotateX(-30deg) rotateY(20deg) ;
    }
    75%{
        transform: rotateX(-30deg) rotateY(-20deg) ;
    }
    100%{
        transform: rotateX(-30deg) rotateY(0deg) ;
    }
}

.cube .top p{
    margin-top: 1vw;
    margin-bottom: 2vw;
    padding: 20px 3.5vw;
    text-align: left;
    font-size: 120%;
    color: white;
    text-align: justify;
    line-height: 200%;
}

.cube .top h1{
    color: white;
    font-size: 120%;
    /* margin-left: -20vw; */
    /* margin-top: -4vw; */
    text-align: center;
    transform: rotate(90deg);
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: left bottom;
    background: rgba(6, 176, 255, 0.6);
    padding:5px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.cube button{
    position:absolute;
    bottom:20px;
    transform: translateX(-50%);
    background: transparent;
    color: white;
    border-radius: 25px;
    border: solid;
    border-color: rgb(201, 188, 6);
    font-size:130%;
    padding:10px 30px
}

/* @media (max-width:700px){
    .cub-content-div p{
       font-size: 15px;
    }
} */

/* =========================================contetnt card sections===================================================== */

/* ******************* cube content div **************************** */

.cub-content-div{
    width: 100%;
    height: 100%;
    padding: 5%;
}
#cube-img{
    width: 13vw;
    height: 15vw;
    padding-top: 2vw;
    padding-left: 2vw;
}

@media (max-width:700px){
    #cube-img{
        width: 23vw;
        height: 25vw;
        padding-top: 2vw;
        padding-left: 2vw;
    }
}

/* @media (max-width:700px){
    .cub-content-div button{
        width: 40vw;
        height: 10vw;
        font-size: 3vw;
    }
} */

/* .cub-content-div h1{
    color: white;
    font-size: 2.5vw;
    margin-left: -20vw;
    margin-top: -4vw;
    text-align: left;
    transform: rotate(90deg);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
} */
/* 
@media (max-width:700px){
    .cub-content-div h1{
        transform: rotate(0);
        font-size: 5vw;
        padding: 0;
        margin: 0;
        margin-top: 10px;
        margin-left: 12vw;
    }
    
} */



/* ******************* cube content div second **************************** */

#cube-img_second{
    width: 12vw;
    padding-top: 2vw;
    padding-left: 2vw;
}

@media (max-width:700px){
    #cube-img_second{
        width: 23vw;
        height: 30vw;
        padding-top: 2vw;
        padding-left: 2vw;
    }
}


.cub-content-div-second{
    width: 100%;
    height: 100%;
    padding: 5%;
}


/* ******************* cube content div third **************************** */

#cube-img-third{
    width: 10vw;
    height: 20vw;
    padding-top: 2vw;
    padding-left: 2vw;
}



.cub-content-div-third{
    width: 100%;
    height: 100%;
    padding: 5%;
}



/* ******************* sub section two cube content div third **************************** */


#sb-two-cube-img_second{
    height: 15vw;
    width: 14vw;
    padding-top: 2vw;
    padding-left: 2vw;
}

@media (max-width:700px){
    #sb-two-cube-img_second{
        width: 23vw;
        height: 25vw;
        padding-top: 2vw;
        padding-left: 2vw;
    }
}


.sb-two-cub-content-div-second{
    width: 100%;
    height: 100%;
    padding: 5%;
}

@media (max-width:700px){
    /* .sb-two-cub-content-div-second p{
       font-size: 15px;
    } */
}


/* @media (max-width:700px){
    .sb-two-cub-content-div-second button{
        width: 40vw;
        height: 10vw;
        font-size: 3vw;
    }
} */

/* 
.sb-two-cub-content-div-second h1{
    color: white;
    font-size: 2.5vw;
    margin-left: -20vw;
    margin-top: -2vw;
    text-align: left;
    transform: rotate(90deg);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
} */


/* @media (max-width:700px){
    .sb-two-cub-content-div-second h1{
        transform: rotate(0);
        font-size: 5vw;
        padding: 0;
        margin: 0;
        margin-top: 10px;
        margin-left: 12vw;
    }
    
} */



/* =======================sub section cube third========================== */


#sb-two-cube-img_third{
    width: 17vw;
    padding-top: 2vw;
    padding-left: 2vw;
}

@media (max-width:700px){
    #sb-two-cube-img_third{
        width: 23vw;
        height: 25vw;
        padding-top: 2vw;
        padding-left: 2vw;
    }
}


