.dropdownmenu ul, .dropdownmenu li {
	margin: 0;
	padding: 0;
}
.dropdownmenu ul {
	/* background: gray; */
	list-style: none;
	width: 100%;
	height: 100%;
}
.dropdownmenu li {
	float: left;
	position: relative;
	width:auto;
	height: 100%;
	width: 160px;
}
.dropdownmenu a {
    
	/* background: #30A6E6; */
	height: 100%;
	color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	font: bold 12px/20px sans-serif;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	-webkit-transition: all .25s ease;
	-moz-transition: all .25s ease;
	-ms-transition: all .25s ease;
	-o-transition: all .25s ease;
	transition: all .25s ease;
}
.dropdownmenu li:hover a {
	background: #fffb00ad;
    text-decoration: solid dashed;
}

#submenu {
	left: 0;
	opacity: 0;
	position: absolute;
	top: 35px;
	visibility: hidden;
	z-index: 1;
}
li:hover ul#submenu {
	opacity: 1;
	top: 60px;	/* adjust this as per top nav padding top & bottom comes */
	visibility: visible;
}
#submenu li {
	float: none;  
	width: 100%;
}
#submenu a:hover {
	height: 100%;
	background: rgb(245,200,20,1);
}
#submenu a {
	background-color:#000000;
}

@media (max-width:725px) {
	.dropdownmenu{display: none;
		}
}
@media (min-width:725px) {
	.dropdownmenu{
		display: flex;
		align-items: center;
		}
}