// body {
//     background: #ff5f6d;
//     background: -webkit-linear-gradient(to right, #ff5f6d, #ffc371);
//     background: linear-gradient(to right, #ff5f6d, #ffc371);
//     min-height: 100vh;
//   }
// style={{backgroundColor:'floralwhite'}}
footer{
  background-color: black;
  border-radius:0px;
}
  #button-addon1 {
    color: #ffc371;
  }
  
  i {
    color: #ffffff;
  }
  
  .form-control::placeholder {
    font-size: 0.95rem;
    color: #aaa;
    font-style: italic;
  }
  
  .form-control.shadow-0:focus {
    box-shadow: none;
  }

  .watch-livetv{
    width:60%;
    height:100px;
    border-radius:20px;
    background-color: rgb(9, 47, 119);
    color:#fff;
    font-size:130%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
