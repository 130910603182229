.vertical-text-pkg-two{
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-weight: bold;
    display: flex;
    flex-direction: row;
    
}

.package-details-content-section{

    // background-color: #00286888;
    padding: 20px;
    flex: 1 1 50%;

    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-weight: bold;
    
}
@media only screen and (max-width:460px) {
    .order-view-box{
        justify-content: space-between;
        width: 100%;
        display: flex;
        flex-direction: column !important;
        background-color: #00286888;
        box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 20px;
    }
}
.cover-camera3{
    margin-right: 10px;
}
.order-view-box{
    margin-top: 20px;
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-direction: row;
    // flex: 1 1 50%;
    background-color: #00286888;
    box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
}
.line-each{
    height: 80%;
    width: 2px;
    color: black;
}