/* Global reset */
*, html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, label, fieldset, input, p, blockquote, th, td { margin:0; padding:0 }
table { border-collapse:collapse; border-spacing:0 }
fieldset, img { border:0 }
address, caption, cite, code, dfn, em, strong, th, var { font-style:normal; font-weight:normal }
ol, ul, li { list-style:none }
caption, th { text-align:left }
h1, h2, h3, h4, h5, h6 { font-size:100%; font-weight:normal }
q:before, q:after { content:'' }

/* Global reset-RESET */
/* The below restores some sensible defaults */
strong { font-weight:bold }
em { font-style:italic }
a img { border:none } /* Gets rid of IE's blue borders */

/* fix padding issues for buttons */
button::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, input[type="reset"]::-moz-focus-inner {
padding: 0 !important;
border: 0 none !important;
}
*{
-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box; /*fix extra padding-width*/   
}