

/* pie diagram svg */


#diagram #Ellipse_3,#diagram #Ellipse_5{
  opacity:0;
}

path{
    stroke-dasharray: 500px;
    stroke-dashoffset: 500px;
    background-color: blue;
}

.statistics-row.anim #diagram path{
  animation: line-anim 10s normal forwards;
}

.statistics-row.anim #diagram #Ellipse_3,
.statistics-row.anim #diagram #Ellipse_5{
  animation: dot-display 2s normal forwards;
  animation-delay: 3.5s;
}

@keyframes dot-display{
  0%{
opacity:0;
  }
  100%{
opacity:1;
  }
}

@keyframes line-anim {
    to
    {
        stroke-dashoffset: 0px;
    }
}
